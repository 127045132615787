<template>
  <div class="bgf7f8f9">
    <div class="tips">付款前请先确认收款商户信息</div>

    <div class="img">
      <div class="img_top">付款给档口</div>
      <div class="img_bottom">{{ info.subject }}</div>
    </div>

    <div class="main">
      <p class="paymenttit">金额</p>
      <div class="payment">
        <span>¥</span>
        <input type="text" readOnly=readOnly id="paymentint" :value="value">
      </div>
      <van-number-keyboard v-model="value" close-button-text="付款" extra-key="." theme="custom" @close="onDelete"
        :show="show" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Notify } from 'vant';
export default {
  name: 'dddd',
  data() {
    return {
      show: true,
      value: '',
      form: null,
      input: '',
      info: '',
      user_id: '',
      url:'https://www.xiaoyuan2.cn:680'
    }
  },


  created() {
    let that = this;
    console.log(this.$route.query);
    this.form = this.$route.query
    console.log(this.form);
    //获取缓存的userid
    this.user_id = localStorage.getItem('user_id')
    // this.getUserCode()
    //获取url全部参数
    this.info = this.GetRequest();
    
  },



  methods: {
    //拉起支付
    open_pay(number) {
      ap.tradePay({
        tradeNO: number
      }, function (res) {
        window.ap.alert(res);
      });
    },

    //获取userid
    get_userid() {
      console.log(this.url);
      let code = null
      let that = this
      ap.getAuthCode({
        appId: '2021003181618960',
        scopes: ['auth_base'],
      }, function (res) {
        code = res.authCode
        window.ap.alert(res)
        axios.post(this.url + '/alipay/getUser?code=' + code).then(res => {
          console.log(res);
          that.user_id = res.data.data
          if (that.user_id) {
            localStorage.setItem('user_id', that.user_id)
            that.get_tradeNO()

          }

        }).catch(err => {
          console.log(err);
        })
      });

    },



    //获取交易号
    get_tradeNO() {
      let that = this
 
      axios.post(this.url + '/alipay/createPay', {
        data: {
          "totalAmount": this.info.amount,
          "subject": this.info.dk_name,
          "buyerId": this.user_id,
          "uuid": '',
          "sellerId": this.info.pid,
          "orderId": this.info.order_id
        }
      }
      ).then(res => {
        console.log(res);
        this.open_pay(res.data.data.tradeNo)
      })
    },

    //付款
    onDelete(e) {
      if (this.value > 0) {
        if (this.user_id) {
          this.get_tradeNO()
        }else{
          this.get_userid()
        }
      } else {
        Notify({ type: 'warning', message: '请输入金额' });
      }

    },



    getUserCode() {
      console.log(window.ap);

      let ua = navigator.userAgent.toLowerCase();
      //判断是不是支付宝
      if (ua.match(/AlipayClient/i) == "alipayclient") {
        console.log('是支付宝');
        window.ap.alert('是支付宝')
        window.ap.getAuthCode({
          appId: '2021003161626309',
          scopes: ['auth_base'],
        }, function (res) {
          window.ap.alert(res);
        });

        // ap.tradePay({
        //   tradeNO: '2023032822001481191407508436'
        // }, function (res) {
        //   window.ap.alert(res);
        // });
        //支付宝获取code
        // window.ap.getAuthCode({
        //   appId: '2019082866519380',
        //   scopes: ['auth_base'],
        // }, function (res) {
        //   // window.ap.alert('res2:'+res.authCode)
        //   localStorage.setItem('code', res.authCode)
        //   window.ap.alert(res)
        //   window.ap.tradePay({
        //      tradeNO: data.body.doc
        //    }, function(res){
        //      window.ap.alert(res.resultCode);
        //      if(res.resultCode=='9000'){
        //        newthat.$vux.toast.text("支付成功");
        //        newthat.toQueryOrder();
        //        if (androidids.length==1) {
        //          newthat.$router.push({
        //            path: "/resultPage",
        //            query: { orderId: androidids[0] }
        //          });
        //        }
        //        androidids = new Array();
        //        orderids = new Array();
        //        newthat.selectIds = [];
        //        newthat.needPayMoneyList = [];
        //      }else if(res.resultCode=='6001'){
        //        newthat.$vux.toast.text("您取消了支付");
        //        // newthat.$vux.toast.text('androidids:'+JSON.stringify(androidids))
        //      } else{
        //        newthat.toQueryOrder();
        //        newthat.$vux.toast.text("支付出错,请稍后再试");
        //      }
        //    })
        // });
      } else {
        console.log('不是支付宝');
        window.ap.alert('不是支付宝')
        //拉起支付
        //支付宝拉起支付

      }
    },

    GetRequest() {
      var url = location.search; //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var strs = url.substr(1);
        strs = strs.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    },




    unescape(html) {
      return html
        .replace(html ? /&(?!#?\w+;)/g : /&/g, '&amp;')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#39;/g, "\'");
    },


  }
}

</script>

<style  scoped>
.bgf7f8f9 {
  font-family: PingFangSC-Regular;

  overflow: hidden;
  height: 100vh;
}

.cfca-keyboard-accessory-v6 {
  display: none !important;
}

.cfca-keyboard-v6 {
  bottom: 20px !important;
}


.img {
  height: 11rem;
  padding: 0 0.08rem;
  background: url(https://mobile.abchina.com/mpay/images/wyfks.png) no-repeat;
  background-size: 100%;
  background-position-y: -0.25rem;
  box-sizing: border-box;
  /* box-shadow: 0 4px 20px 0 rgba(0,0,0,0.06); */
  color: #eeeeee;
}

.img_top {

  padding: 2.75rem 0 1.24rem 1.873rem;
  opacity: 0.9;
  font-size: 1.5rem;
  line-height: 0.28rem;

}

.img_bottom {

  width: 12rem;
  font-size: 1.3rem;
  line-height: 1.45rem;
  padding-left: 1.5rem;

}

.main {
  width: 100%;
  box-sizing: border-box;

}

.paymenttit {
  width: 100%;
  /* margin-bottom: 0.18rem; */
  padding: 0 0.48rem;
  font-size: 0.92rem;
  color: #666666;
  line-height: 0.32rem;
  box-sizing: border-box;
  padding-left: 1.26rem;
}


.payment {

  height: 3rem;
  display: flex;
  align-items: center;
  margin: 0 0.32rem 0.15rem;
  padding-left: 1.26rem;
  border-bottom: 1px solid #f1f1f1;
  color: #333333;
  box-sizing: border-box;
}

.payment span {
  font-weight: 700;
  font-size: 2.65rem;
  line-height: 2.65rem;
}

.payment input {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  border-radius: 0;
  color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  margin: 0;
  padding: 0;
  font-family: Helvetica, 'microsoft yahei', STHeiTi, sans-serif;
  outline: none;
  resize: none;
  -webkit-appearance: none;
  border: none;
  height: 100%;
  font-weight: 700;
  padding-top: 0.06rem;
  padding-left: 0.1rem;
  font-size: 2.36rem;
  margin-left: 10px;
  color: #333333;
  line-height: 2.36rem;
}

.remarks {
  padding-left: 0.48rem;
}

.modify_box {
  display: none;
  padding-right: 0.48rem;
  font-size: 0.28rem;
  line-height: 0.5rem;
}

.modify_message {
  color: #666666;

}

.modify {
  margin-left: 0.24rem;
  color: #FFA900;
  white-space: nowrap;
}

.remarks input {
  /* display: none; */
  width: 90%;
  height: 0.5rem;
  line-height: 0.38rem;
  font-size: 0.28rem;
  caret-color: #00B893;
  color: #666666;
}

.remarks input::-webkit-input-placeholder {
  font-size: 0.28rem;
  color: #C4C4C4;
  line-height: 0.38rem;
}

.remarks input:-moz-placeholder {
  font-size: 0.28rem;
  color: #C4C4C4;
  line-height: 0.38rem;
}

.remarks input::-moz-placeholder {
  font-size: 0.28rem;
  color: #C4C4C4;
  line-height: 0.38rem;
}

.remarks input:-ms-input-placeholder {
  font-size: 0.28rem;
  color: #C4C4C4;
  line-height: 0.38rem;
}

.tips {

  text-align: center;
  margin-top: 1rem;
  font-size: 0.58rem;
  color: #666666;
  line-height: 0.28rem;
}

#del_message {
  height: 15px;
  width: 15px;
  float: right;
  color: #6d7091;
  font-size: 10px;
}

.selectCard {
  font-size: 0;
}

.selectCard img {
  display: inline-block;
  width: 0.44rem;
  height: 0.44rem;
  vertical-align: middle;
}

.selectCard .cardClass {
  display: inline-block;
  width: 0.44rem;
  height: 0.44rem;
  line-height: 0.44rem;
  border-radius: 50%;
  background-color: #ff7975;
  font-size: .22rem;
  color: #fff;
  vertical-align: middle;
  text-align: center;
}

.selectCard .cardNumber {
  display: inline-block;
  width: 3.6rem;
  line-height: 0.44rem;
  margin-right: 0.16rem;
  font-size: 0.32rem;
  color: #333;
  vertical-align: middle;
  letter-spacing: 1px;
  text-align: right;
}

.selectCard .icon-selectCard {
  display: inline-block;
  width: 0.48rem;
  height: 0.48rem;

  background-size: 0.48rem;
  vertical-align: middle;
}

@media (min-height:300px) and (max-height:580px) {
  .cfca-keyboard-v6 {
    bottom: 0 !important;
  }

}

.btnbox {
  display: flex;
  justify-content: flex-end;
}

.btn {
  margin-top: 0.3rem;
  font-size: 0.3rem;
  border: none;
  color: #05c4ad;
  background: #fff;
  display: inline-block;
}

.tit {
  font-size: 0.3rem;
  font-weight: 200;
}

.title {
  font-size: 0.36rem;
  margin-bottom: 0.4rem;
}

.padd {
  padding: 0 0.48rem 0 0.32rem;
}

.pb-cont {
  padding: 0.5rem 0;
}
</style>
